import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import {Box, CssBaseline, Divider} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useContext} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import config from '../../config';
import {CurrentUserContext} from "./CurrentUserContext";
import LogoSection from "./LogoSection";
import GroupIcon from '@mui/icons-material/Group';

const Main = () => {
  return (
    <Box sx={{flex: 1, bgcolor: 'white', height: '100vh', padding: '20px', overflowY: 'auto'}}>
      <Outlet/>
    </Box>
  )
}

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{
      width: '300px',
      height: '100vh',
      overflowY: 'auto',
      bgcolor: 'white',
      borderRight: '1px solid #CCCCCC',
      paddingX: '20px'
    }}>
      <Box sx={{bgcolor: 'background.paper'}}>
        <Box sx={{display: 'flex', paddingTop: '16px'}}>
          <LogoSection/>
        </Box>
        <Divider/>
        <nav>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate(`${config.prefix}/`)}>
                <ListItemIcon>
                  <HomeIcon/>
                </ListItemIcon>
                <ListItemText primary="Back to Home"/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate(`${config.prefix}/admin`)}>
                <ListItemIcon>
                  <PublicIcon/>
                </ListItemIcon>
                <ListItemText primary="Go to Admin Page"/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate(`${config.prefix}/admin/create_invite_code`)}>
                <ListItemIcon>
                  <PublicIcon/>
                </ListItemIcon>
                <ListItemText primary="Create Invite Code"/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate(`${config.prefix}/admin/invite_code_table`)}>
                <ListItemIcon>
                  <PublicIcon/>
                </ListItemIcon>
                <ListItemText primary="Invite Code List"/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate(`${config.prefix}/admin/one_time_invite_code_table`)}>
                <ListItemIcon>
                  <PublicIcon/>
                </ListItemIcon>
                <ListItemText primary="One Time Invite Code List"/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate(`${config.prefix}/admin/user_management`)}>
                <ListItemIcon>
                  <GroupIcon/>
                </ListItemIcon>
                <ListItemText primary="User Management"/>
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </Box>
  )
}

const AdminLayout = () => {
  const user = useContext(CurrentUserContext);

  return (
    <CurrentUserContext.Provider value={user}>
      <Box sx={{display: 'flex', bgcolor: '#EEEEEE', width: '100vw', height: '100vh'}}>
        <CssBaseline/>
        <Sidebar/>
        <Main/>
      </Box>
    </CurrentUserContext.Provider>
  );

}

export default AdminLayout;