import { api } from './api';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (id) => `/users/${id}`
    }),
    getMe: builder.query({
      query: () => `/users/me?timestamp=${new Date().getTime()}`
    }),
    getUserProfile: builder.query({
      query: () => `/user_manage/profile`
    }),
    updateUserProfile: builder.mutation({
      query: (data) => ({
        url: `/user_manage/profile`,
        method: 'PUT',
        body: data
      })
    }),
    getUserToken: builder.query({
      query: () => `/user_manage/token`
    }),
    updateUserToken: builder.mutation({
      query: ({ token }) => ({
        url: `/user_manage/token?token=${token}`,
        method: 'PUT'
      })
    }),
    getUserLLMsTokens: builder.query({
      query: () => `/user_manage/llms_tokens`
    }),
    updateUserLLMsTokens: builder.mutation({
      query: ({ token_class, token }) => ({
        url: `/user_manage/llms_tokens?token_class=${token_class}&token=${token}`,
        method: 'PUT'
      })
    }),
    updateUserPassword: builder.mutation({
      query: ({ password }) => ({
        url: `/user_manage/password?password=${password}`,
        method: 'PUT'
      })
    }),
    loginWithInviteCode: builder.mutation({
      query: ({ invite_code }) => ({
        url: `/invite/?invite_code=${invite_code}`,
        method: 'POST'
      })
    }),
    validateOneTimeInviteCode: builder.mutation({
      query: ({ invite_code }) => ({
        url: `/invite/validate_one_time_invite_code?invite_code=${invite_code}`,
        method: 'POST'
      })
    })
  })
});

export const {
  useGetUserQuery,
  useGetMeQuery,
  useLoginWithInviteCodeMutation,
  useValidateOneTimeInviteCodeMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useGetUserTokenQuery,
  useUpdateUserTokenMutation,
  useGetUserLLMsTokensQuery,
  useUpdateUserLLMsTokensMutation,
  useUpdateUserPasswordMutation
} = userApi;
