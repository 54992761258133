import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// project imports
import navigation from 'menu-items';
import { setMenu } from 'slices/menu';
import { drawerWidth } from 'store/constant';
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Sidebar from './Sidebar';

// assets
import { IconChevronRight } from '@tabler/icons';
import config from 'config';
import { useRef } from 'react';
import { useGetMeQuery } from 'services/user';
import { useCreateGuestUserMutation} from "../../services/auth";
import { logout, setIsLoggedIn } from 'slices/auth';
import { CurrentUserContext } from '../../context/CurrentUserContext';


// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  })
}));

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  const credentials = useSelector((state) => state.auth);
  const { data: user, error, isLoading, refetch } = useGetMeQuery();
  const [createGuestUser, { data: guestUser, error: guestUserError, isSuccess: createGuestUserSuccess }] = useCreateGuestUserMutation();

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.menu.opened);
  const dispatch = useDispatch();

  const handleLeftDrawerToggle = () => {
    dispatch(setMenu({ opened: !leftDrawerOpened }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (credentials.expiration) {
      const isExpired = new Date(credentials.expiration * 1000) < new Date();
      if (isExpired) {
        dispatch(logout());
        navigate(`${config.prefix}/login`);
      } else {
        dispatch(setIsLoggedIn(true));
        const willExpireIn = Math.floor((new Date(credentials.expiration * 1000).getTime() - new Date().getTime()) / 1000);
        if (willExpireIn < 300) {

        }
      }
    }
  }, [credentials, dispatch, navigate]);

  useEffect(() => {
    if (createGuestUserSuccess) {
      window.location.reload();
    }
  }, [createGuestUserSuccess]);

  useEffect(() => {
    if (!credentials.user && config.appName === 'drbioright') {
      createGuestUser();
    }
  }, []);

  useEffect(() => {
    dispatch(setMenu({ opened: !matchDownMd }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);


  useEffect(() => {
    if (credentials.user) {
      refetch();
    }
  }, [credentials.user, refetch])

  return (
    <CurrentUserContext.Provider value={user}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* header */}


        {/* drawer */}
        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

        {/* main content */}
        <Main theme={theme} open={leftDrawerOpened}>
          {/* breadcrumb */}
          <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
          <Outlet />
        </Main>
      </Box>
    </CurrentUserContext.Provider>
  );
};

export default MainLayout;
