// material-ui
import logo from 'assets/images/logo/Logo.png';
import config from 'config';
import { Box, Typography } from '@mui/material';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ size = 1 }) => {
  return (
    <Box id={'logo_container'} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt={config.appName} width={25 * size} />
        <Typography variant="h5" sx={{
          display: 'inline-flex',
          marginLeft: `${size * 8}px`,
          color: '#7c7c7c',
          fontSize: `${size * 16}px`,
          fontWeight: 'bold',
          fontFamily: 'Arial, sans-serif'
        }}>
          {config.appLabel}
        </Typography>
      </Box>
    </Box>
  );
};

export default Logo;
