// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-ellipsis {
    overflow: hidden; /* Ensures the text is clipped */
    white-space: nowrap; /* Keeps the text on a single line */
    text-overflow: ellipsis; /* Adds an ellipsis at the end */
  }

html, body {
    padding: 0;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,gCAAgC;IAClD,mBAAmB,EAAE,oCAAoC;IACzD,uBAAuB,EAAE,gCAAgC;EAC3D;;AAEF;IACI,UAAU;IACV,SAAS;AACb","sourcesContent":[".text-ellipsis {\n    overflow: hidden; /* Ensures the text is clipped */\n    white-space: nowrap; /* Keeps the text on a single line */\n    text-overflow: ellipsis; /* Adds an ellipsis at the end */\n  }\n\nhtml, body {\n    padding: 0;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
