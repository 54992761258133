// material-ui
// import logo from 'assets/images/logo/TCPAPlus.svg';
import logo from 'assets/images/drbioright_logo.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return <div style={{display: 'flex', alignItems: 'center'}}>
        <img src={logo} alt="DrBioRight" width="25" />
        <span style={{fontSize: '16px', fontWeight: 'bold', color: 'grey', marginLeft: '6px', marginTop: '6px'}}>DrBioRight</span>
    </div>;
};

export default Logo;
