// material-ui
import { Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import config from 'config';

const NewChat = () => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom my={1}>
        <Button variant="outlined" color="primary" component={RouterLink} to="/datasets" fullWidth>+ New
          Project</Button>
      </Typography>
    </>
  );
};

export default NewChat;
