import { lazy } from 'react';
import config from 'config';

// project imports
import Loadable from 'ui-component/Loadable';
import AdminLayout from 'layout/AdminLayout';

const AdminPage = Loadable(lazy(() => import('views/admin/')));
const CreateInviteCode = Loadable(lazy(() => import('views/admin/CreateInviteCode')));
const InviteCodeList = Loadable(lazy(() => import('views/admin/InviteCodeList')));
const OneTimeInviteCodeList = Loadable(lazy(() => import('views/admin/OneTimeInviteCodeList')));
const UserManagement = Loadable(lazy(() => import('views/admin/UserManagement')));



// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AdminRoutes = {
  path: '/',
  element: <AdminLayout />,
  children: [
    {
      path: '/admin',
      element: <UserManagement />
    },
    {
      path: '/admin/test',
      element: <AdminPage />
    },
    {
      path: '/admin/user_management',
      element: <UserManagement />
    },
    {
      path: '/admin/create_invite_code',
      element: <CreateInviteCode />
    },
    {
      path: '/admin/invite_code_table',
      element: <InviteCodeList />
    },
    {
      path: '/admin/one_time_invite_code_table',
      element: <OneTimeInviteCodeList />
    },
  ]
};

export default AdminRoutes;
