// assets
import { IconApps, IconListCheck } from '@tabler/icons';

// constant
const icons = { IconApps, IconListCheck };

// ==============================|| Navigation MENU ITEMS ||============================== //

const navigation = {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'apps',
            title: 'AppSpace',
            type: 'item',
            url: '/apps',
            icon: icons.IconApps,
            breadcrumbs: false
        },
        {
            id: 'data',
            title: 'DataSpace',
            type: 'item',
            url: '/data',
            icon: icons.IconApps,
            breadcrumbs: false
        },
        {
          id: 'histories',
          title: 'Histories',
          type: 'item',
          url: '/histories',
          icon: icons.IconListCheck,
          breadcrumbs: false
        },
        {
            id: 'projects',
            title: 'Projects',
            type: 'item',
            url: '/projects',
            icon: icons.IconListCheck,
            breadcrumbs: false
          },
    ]
};

export default navigation;
