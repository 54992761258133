import React, { useEffect, useState } from 'react';

const DeviceSupportChecker = () => {
  const [isUnsupportedDevice, setIsUnsupportedDevice] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Assuming devices with a width of less than 768 pixels are phones/tablets
      const unsupportedScreenWidth = 768;
      if (window.innerWidth < unsupportedScreenWidth) {
        setIsUnsupportedDevice(true);
      } else {
        setIsUnsupportedDevice(false);
      }
    };

    // Check immediately if the device is supported
    handleResize();

    // Add event listener for resize events
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isUnsupportedDevice) {
    return (
      <div style={{padding: '20px', backgroundColor: 'red', color: 'white', textAlign: 'center'}}>
        The screen size of your device is not currently supported. We recommend accessing our website through a desktop browser for the best experience. Support for additional devices is under development and coming soon.
      </div>
    );
  }

  return null; // Don't display anything if the device is supported
};

export default DeviceSupportChecker;
